<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card no-body>
                    <b-tabs card v-model="tabs">
                        <b-tab :title="$t('ects_basic_table').toUpper()" active>
                            <b-row>
                                <b-col md="12" v-if="checkPermission('ects_coursecoordinatorstorecourseassessment')">
                                    <b-button variant="primary" @click="newCourseLoad">{{ $t('new') }}</b-button>
                                </b-col>
                                <b-col md="12">
                                    <b-table
                                        :empty-filtered-text="$t('no_result')"
                                        :empty-text="$t('no_result')"
                                        bordered
                                        striped
                                        responsive
                                        :items="tableData"
                                        :fields="tableFields"
                                        show-empty
                                        class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                        <template #cell(buttons)="row">
                                            <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                                <template #button-content>
                                                    <i class="ri-more-fill"></i>
                                                </template>
                                                <div id="dropdownListHead">
                                                    <b-dropdown-item v-if="checkPermission('ects_coursecoordinatorupdatecourseworkload')" @click="openUpdateCourseLoad(row.item)">
                                                        <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                                    </b-dropdown-item>
                                                    <b-dropdown-item v-if="checkPermission('ects_coursecoordinatordeletecourseworkload')" @click="deleteCourseLoad(row.item)">
                                                        <i class="ri-delete-bin-2-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                                    </b-dropdown-item>
                                                </div>
                                            </b-dropdown>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="4">
                                    <b-alert show variant="secondary">
                                        <b-row>
                                            <b-col cols="12">
                                                {{ $t('ects_total_course_load') }} <b-badge variant="dark">{{totalCourseLoad}}</b-badge>
                                            </b-col>
                                            <b-col cols="12" class="mt-2">
                                                {{ $t('ects_constant') }} <b-badge variant="dark">{{ectsConstant}}</b-badge>
                                            </b-col>
                                            <b-col cols="12" class="text-right">
                                                <span class="font-size-20">÷</span>
                                                <hr />
                                            </b-col>
                                            <b-col cols="12">
                                                {{ $t('ects') }} <b-badge variant="dark">{{ects}}</b-badge>
                                            </b-col>
                                        </b-row>
                                    </b-alert>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab :title="$t('ects_weekly_table').toUpper()">
                            <b-row>
                                <b-col cols="12">
                                    <b-table
                                        :empty-filtered-text="$t('no_result')"
                                        :empty-text="$t('no_result')"
                                        bordered
                                        striped
                                        responsive
                                        :items="tableDataWeekly"
                                        :fields="tableFieldsWeekly"
                                        show-empty
                                        class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                        <template v-for="item in tableFieldsWeekly" v-slot:[`head(${item.key})`]="data">
                                            <span v-if="item.label != ''" class="cursor-pointer" v-b-tooltip.hover :title=item.label>{{ item.key }}</span>
                                        </template>
                                        <template v-for="(item, index) in tableDataWeekly[0]" v-slot:[`cell(${item.week})`]="data">
                                            <b-form-input
                                                v-mask="'###'"
                                                @keyup="saveData(data)"
                                                v-if="data.item[index]"
                                                :id="'b-input-'+(index)+'-'+data.item[index].id"
                                                v-model="data.item[index].time" />
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="4">
                                    <b-alert show variant="secondary">
                                        <b-row>
                                            <b-col cols="12">
                                                {{ $t('ects_total_course_load') }} <b-badge variant="dark">{{totalCourseLoadWeekly}}</b-badge>
                                            </b-col>
                                            <b-col cols="12" class="mt-2">
                                                {{ $t('ects_constant') }} <b-badge variant="dark">{{ectsConstant}}</b-badge>
                                            </b-col>
                                            <b-col cols="12" class="text-right">
                                                <span class="font-size-20">÷</span>
                                                <hr />
                                            </b-col>
                                            <b-col cols="12">
                                                {{ $t('ects') }} <b-badge variant="dark">{{ectsWeekly}}</b-badge>
                                            </b-col>
                                        </b-row>
                                    </b-alert>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components

// Services
import EctsService from "@/services/EctsService"
import ConstantService from "@/services/ConstantService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        course: {
            type: Object,
        },
        forceRefresh: {
            type: Boolean,
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: this.getLocaleField('course_activity.name'),
                    label: this.$t('course_activity')
                },
                {
                    key: 'number_of_activities',
                    label: this.$t('activity_count')
                },
                {
                    key: 'activity_preparation_time',
                    label: this.$t('duration')+' - ('+this.$t('hour')+')'
                },
            ],

            newFormData: {},
            tabs: 0,
            ects: 0,
            totalCourseLoad: 0,
            ectsConstant: 0,
            tableDataWeekly: [],
            tableFieldsWeekly: [
                {
                    key: 'info',
                    label: ''
                }
            ],
            ectsWeekly: 0,
            totalCourseLoadWeekly: 0
        };
    },
    watch: {
        forceRefresh: {
            handler(value) {
                if (value === true) {
                    this.getData()
                }
            }
        },

        tabs: {
            handler(value) {
                if (value == 0) {
                    this.getData()
                } else if(value == 1) {
                    this.getWeeklyData()
                }
            }
        }
    },
    created() {
        this.formData = this.course
        this.getData()
    },
    methods: {
        getData(){
            this.ects = 0
            this.totalCourseLoad = 0

            EctsService.getCourseCoordinatorCourseWorkload(this.course.id, 'simple').then(response => {
                this.tableData = response.data.data
                this.tableData.forEach(itm => {
                    this.totalCourseLoad += itm.number_of_activities * itm.activity_preparation_time
                })
                const config = {
                    params: {
                        'key': 'ects_calculation_multiplier'
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                }
                ConstantService.getValue(config).then(response => {
                    this.ectsConstant = response.data.data
                    this.ects = Math.round(this.totalCourseLoad / response.data.data)
                }).catch(e => {
                    this.showErrors(e, null)
                })
            }).catch(e => {
                this.showErrors(e)
            })
        },

        newCourseLoad() {
            this.course.actionType = "new"
            this.$emit('openUpdate2Modal',this.course,'course-loads-form')
        },

        openUpdateCourseLoad(data){
            this.course.actionType = "update"
            data.code = this.course.code
            data.name = this.course.name
            data.language = this.course.language
            this.$emit('openUpdate2Modal', data, 'course-loads-form')
        },

        deleteCourseLoad(data){
            this.$swal.fire({
                    title: this.$t('attention'),
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                if (result.isConfirmed) {
                    EctsService.deleteCourseCoordinatorCourseWorkload(data.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getData()
                    }).catch(e => {
                        this.showErrors(e)
                    })
                }
            })
        },

        getWeeklyData(){
            this.tableDataWeekly = []
            this.tableFieldsWeekly = [{ key: 'info', label: '' }]
            EctsService.getCourseCoordinatorWeeklyCourseWorkload(this.course.id).then(response => {
                let data = response.data.data
                Object.keys(data[0].weeks).forEach(key => {
                    this.tableFieldsWeekly.push({
                        key: key.toString(),
                        label: key+'. Hafta',
                        week: key
                    })
                })

                data.forEach((itm, index) => {
                    var objData = {}
                    this.tableFieldsWeekly.forEach((item, i) => {
                        if(i > 0){
                            objData[i] = {
                                id: itm.course_activity_id,
                                time: itm.weeks[i],
                                week: item.week,
                            }
                        }
                    })

                    this.tableDataWeekly.push({
                        info: this.getLocaleText(itm, 'course_activity_name'),
                        ...objData
                    })

                    this.computeData()
                })

            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        saveData(data){
            if(data.value.time != null && data.value.time != '' && !isNaN(data.value.time)){
                let formData = {
                    type: 'weekly',
                    course_id: this.course.id,
                    course_activity_id: data.value.id,
                    week: data.value.week,
                    activity_preparation_time: data.value.time
                }
                EctsService.storeCourseCoordinatorCourseWorkload(formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.computeData()
                }).catch(e => {
                    this.showErrors(e, this.$refs.formValidate)
                })
            }
        },

        computeData(){
            let total = 0
            this.totalCourseLoadWeekly = 0
            this.tableDataWeekly.forEach(itm => {
                Object.keys(itm).forEach(key => {
                    if(key > 0 && itm[key].time != null) {
                        total += parseInt(itm[key].time)
                    }
                })
            })
            this.totalCourseLoadWeekly = total
            this.ectsWeekly = Math.round(this.totalCourseLoadWeekly / this.ectsConstant)
        }

    },
}
</script>
